import React, { useState, useEffect } from 'react';
import Navbar from "../../components/Navbar/Index"
import Footer from "../../components/Footer/Index"
import FooterBanner from "../../components/Footer/FooterBanner"
import LoadingScreen from '../../components/LoadingScreen/Index';

export default function Unauthenticated({ children, location }){
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Check if the site has been visited before
        const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
        let fallbackTimeout;
        const handleContentLoaded = function (event) {
            console.log("event: ", event);
            setIsLoading(false);
            localStorage.setItem('hasVisitedBefore', 'true'); 
        };
    
        if (hasVisitedBefore) {
            setIsLoading(false);
            return; 
        }
        else{
             fallbackTimeout = setTimeout(() => {
                handleContentLoaded()
            }, 3000);
        }


    }, []);
    
      useEffect(()=> {
        console.log("is loading: ", isLoading)
      }, [isLoading])

  if (isLoading) {
    return <LoadingScreen />;
  }

    return <React.Fragment>
        <Navbar location={location}/>
        {children}
       <FooterBanner/>
        <Footer/>
    </React.Fragment>
}
