import {
    useStaticQuery,
    graphql
} from "gatsby";

export const useNavigationData = () => {
    return useStaticQuery(
        graphql `
      query navigationQuery {
  allStrapiNavigationPluginfooter {
    edges {
      node {
        title
        type
        uiRouterKey
       
        items {
          slug
          title
          related {
            uiRouterKey
          }
          path
         
        }
        parentNode {
          id
          title
          uiRouterKey
        }
        related {
          id
        }
        id
        menuAttached
        path
        externalPath
      }
    }
  }
  allStrapiNavigationPluginmain {
        edges {
      node {
        title
        type
        uiRouterKey
        published
        items {
          slug
          title
          related {
            uiRouterKey
          }
          path
        }
        # parentNode {
        #   id
        #   title
        #   uiRouterKey
        # }
        related {
          id
        }
        id
        menuAttached
        path
        externalPath
     
      }
    }
  }
      }
    `
    );
  
};