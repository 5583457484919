import './src/styles/global.css';

import React from "react";
import Unauthenticated from './src/layouts/Unauthenticated/Index';

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  
  return <Unauthenticated {...props}>{element}</Unauthenticated>
}
