
import { Fragment, useState, useEffect } from "react";
import React from "react";
import { useGlobalData } from "../../hooks/useGlobalData";
import _ from 'lodash';
import Marquee from "react-fast-marquee";
export default function FooterBanner(){
    const data = useGlobalData();
    console.log("global data in footer banner: ", data);
    const {footer} = data.strapiGlobal;
    let images = [];
    if(footer && footer.banner){
        images = footer.banner.map((image)=> {
            return ( <div className="footer-banner-img-container">
            <img 
                className="footer-banner-img"
                src={image.asset.url} alt={image.title}
            />
        </div>)

        })
    }
    return <div id='footer-banner-container' className=" w-full h-fit  grid-rows-2 bg-green mt-20">
      <Marquee id="footer-marquee" speed={150} direction="left" >
      { images}
      </Marquee>
      <Marquee className="footer-marquee" speed={150} direction="right">
      {  Array(5).fill(images).flat()}
      </Marquee>
    </div>
}