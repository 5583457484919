import axios from 'axios';

export default async function  submitForm(form, formName){
    axios.post(`${process.env.STRAPI_API_URL ? process.env.STRAPI_API_URL: "http://localhost:1337"}/api/ezforms/submit`, {
        formName,    
    formData: form})
    .then((res) => {
      console.log("form submit response: ", res)
      if(res.status == 200){
        alert("message sent!")
        return Promise.resolve(res);
      }
    })
    .catch((error) => {
      // error.response.status Check status code
    }).finally(() => {
    //Perform action in always
    });
}