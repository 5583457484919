import * as React from "react"
import { useFormik } from 'formik';
import * as yup from "yup";
import submitForm from "../../api";

export default function NewsLetter(){
    const schema = yup.object().shape({
        email: yup.string().email().required("An email is required."),
      });
     const formik = useFormik({
        validationSchema: schema,
        // onSubmit: submitForm,
        initialValues: {
          email: '',
        },
        onSubmit: values => {
            console.log("values; ", values)
          submitForm(values, "newsletter").then((res)=> {
            formik.resetForm()
          })
        },
      });

    return (
        <form className="mt-10 md:max-w-sm w-full md:w-fit "
        onSubmit={formik.handleSubmit}
        >
          <div className="flex md:max-w-fit ">
            <label htmlFor="email-address" className="sr-only shrink">
              Email address
            </label>
            <input
          
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              // className=" flex-auto shrink rounded-md border-0 px-0 md:px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              className="flex-grow md:flex-auto md:w-full w-fit px-3 py-2 rounded-md border-0 placeholder:text-gray-400 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" 

              placeholder="Email"
                            
              value={formik.values.email}
                  
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.touched.email &&
                !formik.errors.email
              }
            />
            <button
              type="submit"
              // className=" shrink tracking-widest font-[Arial] rounded-md bg-indigo-600 px-3.5 py-2.5 -ml-2 text-xs md:text-sm font-semibold text-white bg-green shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              className="w-1/3 md:w-auto px-4 py-2 bg-green tracking-widest  -ml-2 text-xs md:text-sm text-white font-semibold rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"

            >
              SUBSCRIBE
            </button>
          </div>
          <p className=" mt-4 text-sm leading-6 text-gray-900">
          </p>
        </form>
    )
}