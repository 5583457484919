
import { Fragment, useState, useEffect } from "react";
import React from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import GCT_LOGO from "../../../static/assets/gtc-ver-color.svg";
import GCT_LOGO_TEXT from "../../../static/assets/gtc-logo-text.svg";
import { useNavigationData } from "../../hooks/useNavigationData";
import { useGlobalData } from "../../hooks/useGlobalData";
import _ from 'lodash';
import tap from "lodash/fp/tap";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import NewsLetter from "../Forms/NewsLetter";
const map = require('lodash/fp/map').convert({ 'cap': false });
export default function Footer(props){
    const data = useNavigationData();
    const globalData = useGlobalData();
    const [navigationItems, setNavigationItems] = useState([])
    const [socialMediaIcons, setSocialMediaIcons] = useState([])
    const [logo, setLogo] = useState()
    const [active, setActive] = useState(true);

    let navigation = null;
    useEffect(()=>{
        console.log('navigation data: ', navigation)
    }, [navigation])

    useEffect(()=> {
      if(globalData){
        console.log("global data: ", globalData)
        const footer = globalData.strapiGlobal.footer;
        if(footer){
          if(footer.logo){
            setLogo(  <img
              className="h-50 max-h-50 max-w-[200px]"
              src={footer.logo.url}
              alt="Good Call Technologies"
            />)
          }
          if(footer.socialMediaIcons){
            const icons = footer.socialMediaIcons.map((icon) => {
              return (
                <a 
                href={icon.link}
                key={icon.title}
                className="text-sm leading-6 text-gray-300 hover:text-white ">
                       <button className="gct-icon-wrapper">
          <img className="gct-icon hover:scale-125" src={icon.image.url} />
        </button>
              </a>
            )
          })
          setSocialMediaIcons(icons)
        }
        }
      }
    if(data){


      navigation = data.allStrapiNavigationPluginfooter.edges;
      console.log('navigation data: ', navigation)
      ;

      const groupedNavigationItems = navigation.reduce((result, { node }) => {
        if (node.type === 'WRAPPER') {
          // add new group for WRAPPER type items
          result.push({
            ...node,
            items: [],
          });
        } else if (node.parentNode) {
          // find the group with the matching parent uiRouterKey
          const parentGroup = result.find(
            (group) => group.uiRouterKey === node.parentNode.uiRouterKey
          );
          // if found, add the item to the items array of the group
          if (parentGroup) {
            parentGroup.items.push(node);
          }
        }
        return result;
      }, []);
      
      console.log('grouped nav items: ', groupedNavigationItems)
      

    const navItems = groupedNavigationItems.filter((node)=> node.items.length > 0).map((node)=> {
      console.log("node: ", node)
      
     return  (
        <div className="flex flex-col md:flex-row justify-items-center w-fit md:block">
          <h3 className="text-sm text-center md:text-left font-semibold leading-6 text-gray">{node.title}</h3>
          <ul role="list" className="mt-6 space-y-4">
             { node && node.items ? node.items.map((item) => {
              console.log("nav sub item: ", item)
              return ( <li key={item.uiRouterKey} className="hover:scale-125 text-center md:text-left">
                <a 
                
                href={item.path || item.externalPath}
                key={item.uiRouterKey}
                className="text-sm  leading-6 text-white">
                  {item.title}
                </a>
              </li>
            )}
            ): null} 
          </ul>
        </div>
     )
    });


      /* let navItem = null;
      switch(node.type){
        case "INTERNAL":
       navItem =  <a
      href={node.path}
      key={node.uiRouterKey}
      className={
        "  px-1 pt-1 nav-link font-medium text-gct-purple-900 sm:block sm:py-2 sm:pl-3 spr-4" 
       
      }
    >
      {node.title}
    </a>
    break;
    case "WRAPPER": 
    console.log("wrapper: ", node)
      }
      return navItem;
    }
    ) */
    setNavigationItems(navItems)
      
    }
  }, [])

  useEffect(() => {
    console.log("finish", navigationItems)
  }, [navigationItems])
    return (
        <footer className="bg-dark " aria-labelledby="footer-heading">
          <div className="px-5 md:px-20 py-16 sm:py-24 lg:px-8 bg-dark lg:py-32">
            <div className="flex sm:grid text-left sm:text-center lg:grid-cols-5   lg:flex-nowrap justify-center md:justify-start lg:justify-between grid-cols-1 gap-6 md:grid-cols-auto  w-full sm:w-auto flex-wrap xl:gap-8">
                <div className=" col-span-6 lg:col-span-1 w-full md:w-fit justify-center">
              <div className="logo-container  mx-auto md:mx-0 w-max  h-fit flex flex-row">
              {logo}
              {/* <div className="h-50 text-white  ml-5 block font-[Knockout-50Welterweight]">

              <h1 className="text-3xl font-extrabold">GOOD CALL</h1>
              <h2 className="-mt-2  text-[22px] font-semibold">TECHNOLOGIES</h2>
              </div> */}
              </div>
              <p className="mt-5 mb-5 sm:mb-0 sm:mt-20 text-gray text-center md:text-left font-thin italic">© 2023 Good Call. All rights reserved.</p>
                </div>
                {navigationItems}

                <div className="w-full col-span-6 lg:col-span-1 flex-1">
          <h3 className="text-sm font-semibold leading-6 text-gray max-width-[250px] text-center md:text-left">SUBSCRIBE AND FOLLOW</h3>
          <ul role="list" className="mt-6 space-y-4">
            <div className="flex justify-between w-[150px] mx-auto md:mx-0">
             { socialMediaIcons} 
            
            </div>
            <NewsLetter/>
          </ul>
        </div>
          </div>
          </div>
        </footer>
      )
}