// src/components/LoadingScreen.js
import React from 'react';
import LoadingAnimation from '../../../static/assets/Logo.mp4'
function LoadingScreen() {
  return (
    <div className="fixed inset-0 flex items-center  bg-gray-black justify-center bg-gray-900 z-50">
      <div className="video-container relative">
        <video className="h-full w-full" autoPlay loop muted>
          <source src={LoadingAnimation} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default LoadingScreen;
