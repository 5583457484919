
import {
    useStaticQuery,
    graphql
} from "gatsby";

export const useGlobalData = () => {
    return useStaticQuery(
        graphql `
      query GlobalpageQuery {
        strapiGlobal {
    favicon {
      url
    }
    logo {
      url
    }
    siteSeo {
      metaDescription
      metaTitle
      shareImage {
        url
      }
    }
    siteTitle
    footer {
      banner {
        asset {
          url
        }
        title
      }
      logo {
        url
      }
      socialMediaIcons {
        title
        link
        image {
          url
        }
        bgColor
      }
    }
  }
      }
    `
    );
  
};

