import { Fragment, useState, useEffect } from "react";
import React from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import GCT_LOGO from "../../../static/assets/gct-logo-purple.svg";
import GCT_LOGO_TEXT from "../../../static/assets/gtc-logo-text.svg";
import "./Navbar.css";
import NavSelector from "./NavSelector";
import { useNavigationData } from "../../hooks/useNavigationData";
import { useGlobalData } from "../../hooks/useGlobalData";
import { navigate } from "gatsby";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar(props) {
  const data = useNavigationData();
  const globalData = useGlobalData();
  const [navigationItems, setNavigationItems] = useState([])
  const [showUserNav, setShowUserNav] = useState(false);
  const [active, setActive] = useState(true);
  const [navBgColor, setNavBgColor] = useState("")
  const pathname = props.location.pathname;
  const [logo, setLogo] = useState( );

  let navigation = null;
  let navItems;

  useEffect(()=>{
    if(globalData){
      console.log("global data: ", globalData)
      console.log("logo url: ", globalData.strapiGlobal.logo.url)
    setLogo(  <img
      className="ml-5 md:ml-0 block h-50 w-auto  group-hover:scale-125 max-w-[200px]"
      src={globalData.strapiGlobal.logo.url}
      alt="GCT Logo"
    />)
    }

    if(data){
      navigation = data.allStrapiNavigationPluginmain.edges;
      console.log('navigation data: ', navigation)
      if(navigation)
    navItems = []
  navigation.forEach((node)=> {
      let navItem = null;
      switch(node.node.type){
        case "INTERNAL":
          navItem =  <a
          href={node.node.path}
          key={node.node.uiRouterKey}
          className={
            "  px-1 pt-1 nav-link font-medium text-gct-purple-900 sm:block sm:py-2 sm:pl-3 spr-4 hover:scale-125" +
            `${active ? " double-nav-border border-double" : ""}`
          }
        >
          {node.node.title}
          {formatPath(pathname) == node.node.path  ? <NavSelector /> : null}
        </a>
        break;
        case "EXTERNAL":
          navItem =  <a
          href={node.node.externalPath}
          key={node.node.uiRouterKey}
          className={
            "  px-1 pt-1 nav-link font-medium text-gct-purple-900 sm:block sm:py-2 sm:pl-3 spr-4 hover:scale-125" +
            `${active ? " double-nav-border border-double" : ""}`
          }
        >
          {node.node.title}
          {formatPath(pathname) == node.node.path  ? <NavSelector /> : null}
        </a>
        break;
          
          }
          console.log("nav item: ", node.node)
          if(node.node.published)
          {
            navItems.push(navItem);
          } 
        }
        )
    setNavigationItems(navItems)
  }

  }, [])

  function formatPath(path){
    if(path.length > 1){
      path = path.slice(0, path.length - 1);
    
    }
   
   return path
  }
  useEffect(()=>{
    console.log("stateful navigation Items: ", navigationItems)
  }, [navigationItems])


  const navlinksContainer = (
    <div className="nav-link-container  inline-flex items-center  px-2 md:space-x-8 sm:space-y-1 sm:pb-4 sm:pt-2 md:flex-row flex-col">
      {navigationItems}
    </div>
    
  );
  return (
    <Disclosure
      as="nav"
      className={` flex items-center absolute inset-x-0 md:p-4 top-0 z-50 ${navBgColor ? "shadow bg-"+navBgColor: ""}`}
      id="gct-nav"
    >
      {({ open }) => {
        if(open){
          console.log("tray open: ", open)
          setNavBgColor("white")
        }else{
          setNavBgColor("");
        }
        return (
        <>
          <div
            className="mx-auto    lg:px-8 w-full  z-[90]"
            id="gct-nav-container"
          >
            <div className="relative flex h-16 justify-between">
              <div
                className="absolute inset-y-0 right-0 flex items-center min-[800px]:hidden"
                id="nav-collapse-button"
              >
                {/* Mobile menu button */}
                <Disclosure.Button className="hamburger absolute right-0 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div
                className="flex flex-shrink-0 items-center group  focus:ring"
                id="company-logo"
                onClick={() => navigate("/")}
              >
               {logo}
                {/* <img
                  className="hidden h-50 w-auto lg:block group-hover:scale-125 outline-1 outline-white"
                  src={GCT_LOGO}
                  alt="GCT Logo"
                /> */}
                {/* <span id="gct-logo-text" className="ml-2 group-hover:scale-125 ">
                <div className="h-50 text-purple ml-5 block font-[Knockout-50Welterweight]">
              <h1 className="whitespace-nowrap text-3xl font-semibold ">GOOD CALL</h1>
              <h2 className="-mt-2 whitespace-nowrap text-[22px] ">TECHNOLOGIES</h2>
              </div>

                </span> */}
              </div>
              <div
                className="flex flex-1 items-center justify-center items-center sm:justify-end"
                id="nav-menu"
              >
                <div className="hidden sm:ml-6 min-[800px]:flex sm:space-x-8 h-fit nav-menu-container">
                  {navlinksContainer}
               
                </div>
              </div>
              {showUserNav ? (
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
                  id="user-nav-menu"
                >
                  <button
                    type="button"
                    className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-lg text-gray-700"
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-lg text-gray-700"
                              )}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-lg text-gray-700"
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              ) : null}
            </div>
          </div>

          <Disclosure.Panel
            className={`min-[800px]:hidden absolute w-screen   z-10 mt-2  rounded-md  py-1 shadow-lg  focus:outline-none nav-hamburger-menu ${navBgColor ? "shadow bg-"+navBgColor: ""}`}
            id="nav-hamburger-menu"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            {navlinksContainer}
   
          </Disclosure.Panel>
        </>
      )}}
    </Disclosure>
  );
}


